<template>
    <div>
        <div class="row mt-2" style="background-color:white;padding:10px">
            <div class="col-md-8">
                 <h5>Assets Details</h5>
                <table class="table table-sm ">
                    <tr>
                        <th>Name</th>
                        <td>{{ orgDetails.name }}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{{ orgDetails.description }}</td>
                    </tr>
                    <tr>
                        <th>Org Code</th>
                        <td>{{ orgDetails.code }}</td>
                    </tr>
                     <tr>
                        <th>Category</th>
                        <td>{{ orgDetails._assetCategoryID.name }}</td>
                    </tr>
                   
                    <tr>
                        <th>Plant Asset Category</th>
                        <td>{{ orgDetails._plantAssetCategoryID.name }}</td>
                    </tr>
                     <tr>
                        <th>Code</th>
                        <td>{{ orgDetails.code }}</td>
                    </tr>
                     <tr>
                        <th>Grade</th>
                        <td>{{ orgDetails.grade }}</td>
                    </tr>
                     <tr>
                        <th>Registration Date</th>
                        <td>{{ orgDetails.created_ts | moment("ddd,Do MMM  YYYY") }}</td>
                    </tr>
            </table>
            </div>
            <div class="col-md-4 text-right">
                <img :src="orgDetails.image" class=" img-thumbnail" width="100px" alt="">
            </div>
            <hr>
           
        </div>
    </div>
</template>
<script>

export default {
    components:{
      
    },
    data(){
        return {
            orgDetails: false,
            plots:[]
        }
    },
    mounted(){
        this.getOrgDetails();
        this.getAssetPlot();
    },
    methods:{
        getOrgDetails(){
            console.log(this.$route.params.id)
            let id = this.$route.params.id
            window.axios.post("assets/"+id,)
            .then((resp) => {
                console.log(resp);
                this.orgDetails= resp.data.data;
            });
        },
        getAssetPlot(){
             let asset_id = this.$route.params.id
             window.axios.get("/asset-plot-alignment/"+asset_id,)
            .then((resp) => {
                console.log(resp);
                this.plots= resp.data.data;
            });
        }
    }
}
</script>
